import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Container from '../components/Container'
import get from 'lodash/get'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { FAQJsonLd } from 'gatsby-plugin-next-seo'
import PageTitle from '../components/PageTitle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Posts extends React.Component {
  componentDidMount() {
    var links = document.links;
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
            links[i].target = '_blank';
            links[i].rel = 'noopener';
        }
    }
    var lisa = document.getElementsByClassName("tab-lisa");
		var bonus = document.getElementsByClassName("tab-bonus");
		var maksu = document.getElementsByClassName("tab-maksu");
		var i;
		for (i = 0; i < lisa.length; i++) {
			lisa[i].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[0].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[0].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.nextElementSibling.nextElementSibling.classList.remove("active");
				}
			});
		}
		var j;
		for (j = 0; j < bonus.length; j++) {
			bonus[j].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[1].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[1].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
					this.nextElementSibling.classList.remove("active");
					this.previousElementSibling.classList.remove("active");
				}
			});
		}					
		var k;
		for (k = 0; k < maksu.length; k++) {
			maksu[k].addEventListener("click", function() {
				this.classList.toggle("active");
				if (this.parentNode.nextElementSibling.childNodes[2].style.display === "block") {
					this.parentNode.nextElementSibling.childNodes[2].style.display = "none";
				} else {
					this.parentNode.nextElementSibling.childNodes[2].style.display = "block";
					this.parentNode.nextElementSibling.childNodes[0].style.display = "none";
					this.parentNode.nextElementSibling.childNodes[1].style.display = "none";
					this.previousElementSibling.classList.remove("active");
					this.previousElementSibling.previousElementSibling.classList.remove("active");
				}
			});
		}
		var talletus = document.getElementsByClassName("talletus");
		var o;
		for (o = 0; o < talletus.length; o++) {
			var trustly = "<img src='/images/maksu/trustly.webp' alt='trustly' class='lazyload'/>"
			var euteller = "<img src='/images/maksu/euteller.webp'  alt='euteller' class='lazyload'/>"
			var visa = "<img src='/images/maksu/visa.webp' alt='visa' class='lazyload'/>"
			var visaelectron = "<img src='/images/maksu/visa-electron.webp' alt='visaelectron' class='lazyload'/>"
			var skrill = "<img src='/images/maksu/skrill.webp' alt='skrill' class='lazyload'/>"
			var mastercard = "<img src='/images/maksu/mastercard.webp' alt='mastercard' class='lazyload'/>"
			var maestro = "<img src='/images/maksu/maestro.webp' alt='maestro' class='lazyload'/>"
			var neteller = "<img src='/images/maksu/neteller.webp' alt='neteller' class='lazyload'/>"
			var paysafecard = "<img src='/images/maksu/paysafecard.webp' alt='paysafecard' class='lazyload'/>"
			var zimpler = "<img src='/images/maksu/zimpler.webp' alt='zimpler' class='lazyload'/>"
      var brite = "<img src='/images/maksu/brite.webp' alt='brite' class='lazyload'/>"
			var str = talletus[o].innerHTML;

			var res = str.replace("Trustly", trustly).replace("Euteller", euteller).replace("Brite", brite).replace("Visa", visa).replace("Visa Electron", visaelectron).replace("Skrill", skrill).replace("Mastercard", mastercard).replace("Maestro", maestro).replace("Neteller", neteller).replace("Paysafecard", paysafecard).replace("Zimpler", zimpler);
			talletus[o].innerHTML = res;
		}
		var tahdet = document.getElementsByClassName("tahdet");
		var t;
		for (t = 0; t < tahdet.length; t++) {
			var two = "<img src='/images/star2.webp' alt='2' class='lazyload'/>"
			var twohalf = "<img src='/images/star2half.webp' alt='kaksi' class='lazyload'/>"
			var three = "<img src='/images/star3.webp' alt='kolme' class='lazyload'/>"
			var threehalf = "<img src='/images/star3half.webp' alt='kolme' class='lazyload'/>"
			var four = "<img src='/images/star4.webp' alt='neljä' class='lazyload'/>"
			var fourhalf = "<img src='/images/star4half.webp' alt='neljä' class='lazyload'/>"
			var five = "<img src='/images/star5.webp' alt='viisi' class='lazyload'/>"
			var rate = tahdet[t].innerHTML;
			var star = rate.replace("2", two).replace("Kaksi puoli", twohalf).replace("3", three).replace("Kolme puoli", threehalf).replace("4", four).replace("Neljä puoli", fourhalf).replace("5", five);
			tahdet[t].innerHTML = star;
		}
  }
  render() {
    const posts = get(this, 'props.data.allContentfulPost.edges')
    const data = get(this, 'props.data.contentfulEtusivu')
    let featuredPost
    let ogImage

    try {
      featuredPost = posts[0].node
    } catch (error) {
      featuredPost = null
    }
    try {
      ogImage = posts[0].node.heroImage.ogimg.src
    } catch (error) {
      ogImage = null
    }
    return(
      <Layout>
    <GatsbySeo
      title='Parhaat kasinot - kasinosivut suomalaisille! | Kasinosivu.com'
      description='Parhaat kasinot, näillä sinäkin haluat pelata! Netin luotettavat kasinosivut koottuna! Kattavin kasino-valikoima meiltä! Tervetuloa Kasinosivulle!'  
    />
     <FAQJsonLd
        questions={[
          { question: 'Miten liityn kasinolle?', answer: 'Asiakkaan liittyminen kasinopalvelun käyttäjäksi on tehty varsin helpoksi. Siirryt vain kasinon nettisivuille ja klikkaat "rekisteröidy" tai "avaa pelitili" -nappulaa. Nykyään osa kasinoista toimii myös ilman rekisteröitymistä ja tunnistautuminen hoituu verkkopankkitunnuksilla.' },
          { question: 'Tarvitseeko minun ladata kasinon ohjelmisto koneelleni / kännykkääni?', answer: 'Kasinoiden ilmestyessä nettiin oli varsin yleistä, että ne toimivat ladattavana ohjelmistona. Nykyään lähes kaikki kasinot toimivat suoraan selaimessasi joten lataamiselle ei ole tarvetta.' },
          { question: 'Ovatko netin kasinot laillisia Suomesssa?', answer: 'Netistä löytämäsi kasinot ovat täysin laillisia suomalaisille(kin) pelaajille. EU-alueelta myönnetyn lisenssin alaisuudessa toimivat kasinot ovat lisäksi verovapaita.' },
          { question: 'Mikä on paras kasino?', answer: 'Yhtä ainoaa parasta kasinoa on vaikea nimetä. Jokaisella pelaajalla on omat mieltymyksensä, mutta hyvän kasinon merkkeinä suomalaisen pelaajan näkökulmasta voidaan pitää nopeita rahansiirtoja, EU-lisenssiä sekä vakavaraista taustayhtiötä. Sivuiltamme löydät runsaasti lisäinfoa parhaista kasinoista...' },
          { question: 'Ovatko kasinoilla pelattavat pelit reiluja ja rehellisiä?', answer: 'Pelit ovat rehellisiä. Kasinoiden tarjoamissa peleissä on aina määritelty pelikohtainen palautusprosentti. Palautusprosentti eli RTP vaihtuu peli- ja kasinokohtaisesti, tämä määrittää pelin reiluuden pelaajan näkökulmasta. ' },
          { question: 'Voinko pelata useammalla kuin yhdellä kasinolla?', answer: 'Voit. Voit pelata useammalla kasinolla vaikka samanaikaisesti. Kasinosivu.com suositteleekin asiakastilin avaamista useammalla kasinolle tarjousten ja bonusten maksimaaliseen hyödyntämiseen.' },
        ]}
      />
      <Container>
      <PageTitle>Parhaat kasinot</PageTitle>
      <table>
        <tbody>
        {data.etusivuTopLista.map(({listalogo,logonTiedostonimi,kasinonListanimi,tahtiarvostelu,bonus,ilmaiskierrokset,afflink,etusivuntoplista}) => (
          <tr className={casinolistStyle.casino}>
            <td className={casinolistStyle.top}>
              <div className={`${casinolistStyle.logo} ${casinolistStyle.topLogo}`}>
              <a href={afflink} target="_blank"><img src={`/images/logo/${logonTiedostonimi}`} alt={listalogo.title}/></a>
                <div className={casinolistStyle.name}>
                <a href={afflink} target="_blank"><h5 className={casinolistStyle.title}>{kasinonListanimi}</h5></a>
                  <span className={`${casinolistStyle.rate} tahdet`}>{tahtiarvostelu}</span>
                </div>
              </div>
              <div className={casinolistStyle.etucontent}>
                <div>{documentToReactComponents(etusivuntoplista.json)}</div>
              </div>
            </td>
            <td className={casinolistStyle.bottom}>
              <div className={casinolistStyle.etuexpand}>
                <div className={casinolistStyle.etucontentBox}>
                  <div className={casinolistStyle.contentInfo}>
                    <div className={casinolistStyle.offerItem}>
                      <i>Bonukset:</i>
                      <h4>{bonus}</h4>
                    </div>
                    <div className={casinolistStyle.offerItem}>
                      <i>Ilmaiskierrokset:</i>
                      <h4>{ilmaiskierrokset}</h4>
                    </div>
                  </div>
                  <div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">Kasinolle</a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <div className="ylaosa">{documentToReactComponents(data.ylosanTeksti1.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
        <CardList>
          <Card {...featuredPost} featured />
          {posts.slice(1).map(({ node: post }) => (
            <Card key={post.id} {...post} />
          ))}
        </CardList>
        <div className="alaosa">{documentToReactComponents(data.alaosanTeksti1.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <center><h3>TOP5-Kasinot</h3></center>
      <table>
        <tbody>
          {data.etusivuTop5.map(({listalogo,logonTiedostonimi,kasinonListanimi,tahtiarvostelu,bonus,ilmaiskierrokset,afflink,kasinonlisatiedotetu5,bonustiedotEtusivu5,rahansiirto}) => (
          <tr className={casinolistStyle.casino}>
            <td className={casinolistStyle.top}>
              <div className={casinolistStyle.logo}>
                <img data-src={`/images/logo/${logonTiedostonimi}`}  alt={listalogo.title} className="lazyload"/>
                <div className={casinolistStyle.name}>
                  <h5 className={casinolistStyle.title}>{kasinonListanimi}</h5>
                  <span className={`${casinolistStyle.rate} tahdet`}>{tahtiarvostelu}</span>
                </div>
              </div>
              <div className={casinolistStyle.content}>
                <div className={casinolistStyle.contentBox}>
                  <div className={casinolistStyle.contentInfo}>
                    <div className={casinolistStyle.offerItem}>
                      <i>Bonukset</i>
                      <h4>{bonus}</h4>
                    </div>
                    <div className={casinolistStyle.offerItem}>
                      <i>Ilmaiskierrokset</i>
                      <h4>{ilmaiskierrokset}</h4>
                    </div>
                  </div>
                  <div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">Pelaa heti!</a>
                  </div>
                </div>
              </div>
            </td>
            <td className={casinolistStyle.bottom}>
              <div className={casinolistStyle.expand}>
                <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
                <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
                <div className={`${casinolistStyle.expandTabs} tab-maksu`}><span>Maksutavat</span></div>
              </div>
              <div className={casinolistStyle.expandContent}>
                <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(kasinonlisatiedotetu5.json)}</div>
                <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(bonustiedotEtusivu5.json)}</div>
                <div className={`${[casinolistStyle.expandInfo, casinolistStyle.expandMaksu].join(' ')} maksu`} >
                  <h5>Talletus- ja kotiutustavat: </h5><div className="talletus"> {rahansiirto}</div>
                </div>
              </div>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      <div className="alaosa2">{documentToReactComponents(data.alaosanTeksti2.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <center><h3>TOP5 kasino bonukset</h3></center>
      <table>
        <tbody>
          {data.etusivuBonuslista.map(({listalogo,logonTiedostonimi,kasinonListanimi,tahtiarvostelu,bonus,ilmaiskierrokset,afflink,kasinonlisatiedotetu5,bonustiedotEtusivu5,rahansiirto}) => (
          <tr className={casinolistStyle.casino}>
            <td className={casinolistStyle.top}>
              <div className={casinolistStyle.logo}>
                <img data-src={`/images/logo/${logonTiedostonimi}`} alt={listalogo.title} className="lazyload"/>
                <div className={casinolistStyle.name}>
                  <h5 className={casinolistStyle.title}>{kasinonListanimi}</h5>
                  <span className={`${casinolistStyle.rate} tahdet`}>{tahtiarvostelu}</span>
                </div>
              </div>
              <div className={casinolistStyle.content}>
                <div className={casinolistStyle.contentBox}>
                  <div className={casinolistStyle.contentInfo}>
                    <div className={casinolistStyle.offerItem}>
                      <i>Bonukset</i>
                      <h4>{bonus}</h4>
                    </div>
                    <div className={casinolistStyle.offerItem}>
                      <i>Ilmaiskierrokset</i>
                      <h4>{ilmaiskierrokset}</h4>
                    </div>
                  </div>
                  <div className={casinolistStyle.contentBtn}>
                    <a className={casinolistStyle.btn} href={afflink} target="_blank">Pelaa heti!</a>
                  </div>
                </div>
              </div>
            </td>
            <td className={casinolistStyle.bottom}>
              <div className={casinolistStyle.expand}>
                <div className={`${casinolistStyle.expandTabs} tab-lisa`}><span>Lisätiedot</span></div>
                <div className={`${casinolistStyle.expandTabs} tab-bonus`}><span>Bonustiedot</span></div>
                <div className={`${casinolistStyle.expandTabs} tab-maksu`}><span>Maksutavat</span></div>
              </div>
              <div className={casinolistStyle.expandContent}>
                <div className={`${casinolistStyle.expandInfo} lisa`} >{documentToReactComponents(kasinonlisatiedotetu5.json)}</div>
                <div className={`${casinolistStyle.expandInfo} bonus`} >{documentToReactComponents(bonustiedotEtusivu5.json)}</div>
                <div className={`${[casinolistStyle.expandInfo, casinolistStyle.expandMaksu].join(' ')} maksu`} >
                  <h5>Talletus- ja kotiutustavat: </h5><div className="talletus"> {rahansiirto}</div>
                </div>
              </div>
            </td>
          </tr>
          ))}
        </tbody>
      </table>
      <div className="alaosa3">{documentToReactComponents(data.alaosanTeksti3.json, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: node => (
            <img className="lazyload page-img"
              data-src={`${node.data.target.fields.file["en-US"].url}`}
              alt={node.data.target.fields.title["en-US"]}
            />
          ),
        },
      })}</div>
      <div>
        <h2>Usein kysyttyä kasinoista:</h2>
        <p>Olemme keränneet alle muutaman kysymyksen joita meiltä tiedustellaan varsin usein kasinoista ja kasinolla pelaamisesta.</p>
        <div>
          <div><h5>Miten liityn kasinolle? </h5></div>
          <p>Asiakkaan liittyminen kasinopalvelun käyttäjäksi on tehty varsin helpoksi. Siirryt vain kasinon nettisivuille ja klikkaat "rekisteröidy" tai "avaa pelitili" -nappulaa. Nykyään osa kasinoista toimii myös ilman rekisteröitymistä ja tunnistautuminen hoituu verkkopankkitunnuksilla.</p>
        </div>
        <div>
          <div><h5>Tarvitseeko minun ladata kasinon ohjelmisto koneelleni / kännykkääni?</h5></div>
          <p>Kasinoiden ilmestyessä nettiin oli varsin yleistä, että ne toimivat ladattavana ohjelmistona. Nykyään lähes kaikki kasinot toimivat suoraan selaimessasi joten lataamiselle ei ole tarvetta.</p>
        </div>                
        <div>
          <div><h5>Ovatko netin kasinot laillisia Suomesssa?</h5></div>
          <p>Netistä löytämäsi kasinot ovat täysin laillisia suomalaisille(kin) pelaajille. EU-alueelta myönnetyn lisenssin alaisuudessa toimivat kasinot ovat lisäksi verovapaita.</p>
        </div>     
        <div>
          <div><h5>Mikä on paras kasino?</h5></div>
          <p>Yhtä ainoaa parasta kasinoa on vaikea nimetä. Jokaisella pelaajalla on omat mieltymyksensä, mutta hyvän kasinon merkkeinä suomalaisen pelaajan näkökulmasta voidaan pitää nopeita rahansiirtoja, EU-lisenssiä sekä vakavaraista taustayhtiötä. Sivuiltamme löydät runsaasti lisäinfoa parhaista kasinoista...</p>
        </div> 
        <div>
          <div><h5>Ovatko kasinoilla pelattavat pelit reiluja ja rehellisiä?</h5></div>
          <p>Pelit ovat rehellisiä. Kasinoiden tarjoamissa peleissä on aina määritelty pelikohtainen palautusprosentti. Palautusprosentti eli RTP vaihtuu peli- ja kasinokohtaisesti, tämä määrittää pelin reiluuden pelaajan näkökulmasta. </p>
        </div> 
        <div>
          <div><h5>Voinko pelata useammalla kuin yhdellä kasinolla? </h5></div>
          <p>Voit. Voit pelata useammalla kasinolla vaikka samanaikaisesti. Kasinosivu.com suositteleekin asiakastilin avaamista useammalla kasinolle tarjousten ja bonusten maksimaaliseen hyödyntämiseen. </p>
        </div>            
      </div>
      </Container>
    </Layout>
    )
  }
}

export default Posts

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    contentfulEtusivu {
      title
      id
      slug
      ylosanTeksti1 {
        json
      }
      alaosanTeksti1 {
        json
      }
      alaosanTeksti2 {
        json
      }
      alaosanTeksti3 {
        json
      }
      etusivuTopLista {
        kasinonNimi
        kasinonListanimi
        bonus
        ilmaiskierrokset
        tahtiarvostelu
        logonTiedostonimi
        listalogo {
          title
          file {
            url
          }
        }
        afflink
        etusivuntoplista {
          json
        }
      }
      etusivuTop5 {
        kasinonNimi
        kasinonListanimi
        bonus
        ilmaiskierrokset
        rahansiirto
        tahtiarvostelu
        logonTiedostonimi
        listalogo {
          title
          file {
            url
          }
        }
        afflink
        kasinonlisatiedotetu5 {
          json
          }
        bonustiedotEtusivu5 {
          json
        }
      }
      etusivuBonuslista {
        kasinonNimi
        kasinonListanimi
        bonus
        ilmaiskierrokset
        rahansiirto
        tahtiarvostelu
        logonTiedostonimi
        listalogo {
          title
          file {
            url
          }
        }
        afflink
        kasinonlisatiedotetu5 {
          json
          }
        bonustiedotEtusivu5 {
          json
        }
      }
    }
  }
`

